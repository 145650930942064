/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import React from 'react';

import { CookieProvider } from './src/context/CookieContext';

// eslint-disable-next-line import/prefer-default-export
export const wrapRootElement = ({ element }) => (
  <CookieProvider>{element}</CookieProvider>
);
