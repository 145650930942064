// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-resource-article-js": () => import("/tmp/homepage/src/templates/resource-article.js" /* webpackChunkName: "component---src-templates-resource-article-js" */),
  "component---src-pages-404-js": () => import("/tmp/homepage/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-accessibility-js": () => import("/tmp/homepage/src/pages/accessibility.js" /* webpackChunkName: "component---src-pages-accessibility-js" */),
  "component---src-pages-contact-js": () => import("/tmp/homepage/src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-cookies-js": () => import("/tmp/homepage/src/pages/cookies.js" /* webpackChunkName: "component---src-pages-cookies-js" */),
  "component---src-pages-faqs-js": () => import("/tmp/homepage/src/pages/faqs.js" /* webpackChunkName: "component---src-pages-faqs-js" */),
  "component---src-pages-index-js": () => import("/tmp/homepage/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-js": () => import("/tmp/homepage/src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-resources-js": () => import("/tmp/homepage/src/pages/resources.js" /* webpackChunkName: "component---src-pages-resources-js" */),
  "component---src-pages-terms-js": () => import("/tmp/homepage/src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/tmp/homepage/.cache/data.json")

